<template>
    <div class="sidebar">
        <v-card-title class="title">{{ getUsername }}</v-card-title>
        <v-layout>
            <v-navigation-drawer floating permanent>
                <v-list nav class="list">
                    <v-list-item class="list-item"
                        ><a @click="goToPage('/adminpanel')">{{
                            page1
                        }}</a></v-list-item
                    >
                    <v-list-item class="list-item"
                        ><a @click="goToPage('/groups')">{{
                            page2
                        }}</a></v-list-item
                    >
                    <v-list-item class="list-item"
                        ><a @click="goToPage('/members/unassigned')">{{
                            page3
                        }}</a></v-list-item
                    >
                    <v-list-item class="list-item"
                        ><a @click="goToPage('/members')">{{
                            page4
                        }}</a></v-list-item
                    >
                </v-list>
            </v-navigation-drawer>
            <v-main style="min-height: 570px"></v-main>
        </v-layout>
    </div>
</template>

<script>
import { router } from "@/router";

export default {
    data: () => ({
        page1: "Panel główny",
        page2: "Twoje grupy",
        page3: "Oczekujący",
        page4: "Uczestnicy",
    }),
    computed: {
        getUsername() {
            return this.$store.state.username;
        },
    },
    methods: {
        goToPage(route) {
            router.push(route);
        },
    },
};
</script>

<style scoped>
a {
    margin: 0px;
    padding: 0px;
}

.title {
    color: #0396a6;
    font-size: 1.6rem;
    font-weight: 1000;
    padding: 45px 0px 0px 0px;
    margin-left: 15px;
}

.sidebar {
    border-right: 1px solid #a0d3d9;
    height: 100%;
}

.list {
    margin-top: 25px;
}

.list-item {
    font-size: 1.07rem !important;
    font-weight: 400;
}
</style>
