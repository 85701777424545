import axios from "axios";

const apiURL = process.env.VUE_APP_API_URL;

const messages = {
    positive: "Grupa została zaktualizowana",
    error: "Pojawił się bład: ",
};

export const modifyGroup = (
    store,
    id,
    groupName,
    groupDescription,
    maxMembers,
    classLevel
) => {
    return new Promise((resolve, reject) => {
        const config = {
            headers: {
                Authorization: "Bearer " + store.state.token,
            },
        };

        const groupData = {
            name: groupName,
            description: groupDescription,
            maximumMembersCount: maxMembers,
            advancementLevel: classLevel,
        };

        axios
            .put(apiURL + "group/" + id, groupData, config)
            .then(() => {
                resolve(messages.positive);
            })
            .catch((error) => {
                reject(messages.error + error.response.data);
            });
    });
};
