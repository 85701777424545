<template>
    <v-app>
        <v-main>
            <router-view></router-view>
        </v-main>
    </v-app>
    <MainFooter />
</template>

<script>
import router from "./router";

export default {
    name: "App",
    router,

    data: () => ({
        //
    }),
    mounted() {
        document.title = "Class Management App";
    },
};
</script>
