<template>
    <h1>{{ $route.name }}</h1>
</template>

<script>
export default {
    computed: {
        currentRouteName() {
            return this.$route.name;
        },
    },
};
</script>

<style scoped>
h1 {
    color: #0396a6;
    margin: 20px;
}
</style>
