import axios from "axios";

const apiURL = process.env.VUE_APP_API_URL;

export function getGroupsList() {
    return axios.get(apiURL + "public/group/list").then((response) => {
        const returnedGroups = [...response.data];

        const transformedGroups = returnedGroups.map(function (obj) {
            var result = {
                id: obj.id,
                name: obj.name,
                description: obj.description,
                advancementLevel: obj.advancementLevel,
                events: obj.events,
            };

            for (let id in obj) {
                if (Object.prototype.hasOwnProperty.call(obj, id)) {
                    result[id] = obj[id];
                }
            }

            return result;
        });
        return transformedGroups;
    });
}

export function selectGroupByLevel(advancementLevel) {
    return getGroupsList().then((transformedGroups) => {
        let selectedGroups = [];

        transformedGroups.forEach((group) => {
            if (
                group.advancementLevel === advancementLevel &&
                group.currentMembersCount < group.maximumMembersCount &&
                group.events.length > 0
            ) {
                selectedGroups.push(group);
            }
        });

        return selectedGroups;
    });
}

export function getEventsList() {
    getGroupsList().then((transformedGroups) => {
        const events = [];
        transformedGroups.forEach((group) => {
            let groupEvents = [...group.events];
            events.push(groupEvents);
        });
        console.log(events);
    });
}
