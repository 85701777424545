<template>
    <v-container class="block">
        <v-table fixed-header height="155px">
            <thead>
                <tr>
                    <th class="text-left"><h4>Dzień</h4></th>
                    <th class="text-left"><h4>Godzina</h4></th>
                    <th><CreateEvent :id="id" /></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="event in events" :key="event.id">
                    <td class="text-left">{{ event.dayOfWeek }}</td>
                    <td class="text-left">{{ event.time }}</td>
                    <td>
                        <DeleteEvent :id="event.id" />
                    </td>
                </tr>
            </tbody>
        </v-table>
    </v-container>
</template>

<script>
import { getGroup } from "@/functions/get-group";
import DeleteEvent from "../EventActions/DeleteEvent.vue";
import CreateEvent from "../EventActions/CreateEvent.vue";

export default {
    data() {
        return {
            events: [],
            eventDay: "",
        };
    },
    components: {
        CreateEvent,
        DeleteEvent,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    mounted() {
        this.fetchGroup();
    },
    methods: {
        async fetchGroup() {
            try {
                const returnedGroup = await getGroup(this.$store, this.id);
                this.events = returnedGroup.events;
            } catch (error) {
                console.log(error);
            }
        },
    },
};
</script>

<style scoped>
.block {
    max-height: 100%;
    text-align: center;
    border: 1px solid #0396a6;
    border-radius: 25px;
}

.red-button {
    font-weight: 400;
    background: #e76161;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
    border-radius: 100px;
    color: #fcfcfc;
    text-transform: unset !important;
}
</style>
