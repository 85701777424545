<template>
    <v-container class="block">
        <v-table fixed-header height="155px">
            <thead>
                <tr>
                    <th class="text-left"><h4>Poziom</h4></th>
                    <th class="text-left"><h4>Opis</h4></th>
                    <th class="text-left"><h4>Studenci</h4></th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="text-left">{{ group.advancementLevel }}</td>
                    <td class="text-left">{{ group.description }}</td>
                    <td class="text-left">
                        {{ members.length }}/{{ group.maximumMembersCount }}
                    </td>
                </tr>
            </tbody>
        </v-table>
    </v-container>
</template>

<script>
import { getGroup } from "@/functions/get-group";

export default {
    data() {
        return {
            group: {},
            members: [],
        };
    },
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    mounted() {
        this.fetchGroup();
    },
    methods: {
        async fetchGroup() {
            try {
                const returnedGroup = await getGroup(this.$store, this.id);
                this.group = returnedGroup;
                this.members = returnedGroup.members;
            } catch (error) {
                console.log(error);
            }
        },
    },
};
</script>

<style scoped>
.block {
    max-height: 100%;
    text-align: center;
    border: 1px solid #0396a6;
    border-radius: 25px;
}
</style>
