import axios from "axios";
import { router } from "../router";

const apiURL = process.env.VUE_APP_API_URL;

export const createEvent = (store, groupId, eventDayOfWeek, eventTime) => {
    const config = {
        headers: {
            Authorization: "Bearer " + store.state.token,
        },
        params: { groupId },
    };

    const eventData = {
        dayOfWeek: eventDayOfWeek,
        time: eventTime,
    };

    axios
        .post(apiURL + "event", eventData, config)
        .then(() => {
            router.push("/groups");
        })
        .catch((error) => {
            console.log(error.response.data);
        });
};
