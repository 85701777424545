<template>
    <v-container class="block">
        <h2>Grupy</h2>
        <v-table fixed-header height="280px">
            <thead>
                <tr>
                    <th class="text-left"><h4>Nazwa</h4></th>
                    <th class="text-left"><h4>Poziom</h4></th>
                    <th class="text-left"><h4>Uczestnicy</h4></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="group in groups.slice(0, 4)" :key="group.id">
                    <td class="text-left">{{ group.name }}</td>
                    <td class="text-left">
                        {{
                            getTranslatedAdvancementLevel(
                                group.advancementLevel
                            )
                        }}
                    </td>
                    <td class="text-left">{{ group.members.length }}</td>
                </tr>
            </tbody>
        </v-table>
    </v-container>
</template>

<script>
import { getGroups } from "@/functions/get-groups";
import { getTranslatedAdvancementLevel } from "@/functions/class-level";

export default {
    data: () => {
        return {
            groups: [],
        };
    },
    mounted() {
        this.fetchGroups();
    },
    methods: {
        async fetchGroups() {
            try {
                const returnedGroups = await getGroups(this.$store);
                this.groups = returnedGroups;
            } catch (error) {
                console.log(error);
            }
        },
        getTranslatedAdvancementLevel,
    },
};
</script>

<style>
.block {
    max-height: 100%;
    text-align: center;
    border: 1px solid #0396a6;
    border-radius: 25px;
}
.data-block {
    width: 100%;
    border-radius: 10px;
    padding: 30px;
}

.title {
    margin: 5px 0px 20px 20px;
}

.table-color {
    background-color: #e7f2f3;
}

.header {
    border-right: 1px solid #0396a6;
}

.data {
    border-top: 1px solid #0396a6;
    border-right: 1px solid #0396a6;
}

.data-right {
    border-top: 1px solid #0396a6;
}

.no-border {
    border: 0px !important;
    width: 10px;
}
</style>
