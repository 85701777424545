export const getDays = () => {
    return [
        { title: "Poniedziałek", value: "MONDAY" },
        { title: "Wtorek", value: "TUESDAY" },
        { title: "Środa", value: "WEDNESDAY" },
        { title: "Czwartek", value: "THURSDAY" },
        { title: "Piątek", value: "FRIDAY" },
        { title: "Sobota", value: "SUNDAY" },
        { title: "Niedziela", value: "SATURDAY" },
    ];
};
