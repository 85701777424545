<template>
    <v-row justify="center">
        <v-dialog
            v-model="dialog"
            persistent
            width="500px"
            transition="dialog-bottom-transition"
        >
            <template v-slot:activator="{ props }">
                <a class="red-button" v-bind="props"> Usuń grupę </a>
            </template>
            <v-card>
                <v-card-title class="text-h5">
                    Czy na pewno chcesz usunąć grupę?
                </v-card-title>
                <v-card-text
                    >Grupa zostanie usunięta wraz z przypisanymi do niej
                    wydarzeniami oraz studentami. Po tej akcji ich odzyskanie
                    nie będzie już dłużej możliwe.</v-card-text
                >
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue" variant="text" @click="dialog = false">
                        Anuluj
                    </v-btn>
                    <v-btn
                        color="red"
                        variant="text"
                        @click="deleteGroup(this.$store, this.id)"
                    >
                        Usuń
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import deleteGroup from "@/functions/delete-group";

export default {
    data() {
        return {
            dialog: false,
        };
    },
    props: {
        id: {
            type: Number,
            required: true,
        },
    },
    methods: {
        deleteGroup,
    },
};
</script>

<style scoped>
.red-button {
    margin-left: 20px;
    font-weight: 400;
    background: #e76161;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
    border-radius: 100px;
    color: #fcfcfc;
    text-transform: unset !important;
}
</style>
