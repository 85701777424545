<template>
    <v-row justify="center">
        <v-dialog
            v-model="dialog"
            persistent
            width="1024"
            transition="dialog-bottom-transition"
        >
            <template v-slot:activator="{ props }">
                <a class="button" v-bind="props"> Modyfikuj grupę </a>
            </template>
            <v-form
                @submit.prevent="
                    modifyGroup(
                        $store,
                        this.id,
                        this.group.name,
                        this.group.description,
                        this.group.maximumMembersCount,
                        this.group.advancementLevel
                    )
                "
            >
                <v-card loading>
                    <v-card-title>
                        <span class="text-h5">Modyfikowanie grupy</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12" sm="6" md="4">
                                    <v-text-field
                                        variant="outlined"
                                        label="Nazwa grupy"
                                        v-model="group.name"
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                    <v-select
                                        variant="outlined"
                                        label="Poziom"
                                        v-model="group.advancementLevel"
                                        :items="availableLevels"
                                    ></v-select>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                    <v-text-field
                                        variant="outlined"
                                        label="Maksymalna liczba uczestników"
                                        v-model="group.maximumMembersCount"
                                        type="number"
                                        persistent-hint
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-textarea
                                        variant="outlined"
                                        label="Opis"
                                        v-model="group.description"
                                        required
                                    ></v-textarea>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="blue"
                            variant="text"
                            @click="dialog = false"
                        >
                            Zamknij
                        </v-btn>
                        <v-btn color="success" variant="text" type="submit">
                            Zapisz
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
    </v-row>
    <SuccessSnackbar
        :ref="successDialog"
        :start="successNotification"
        :notificationText="notification"
    />
</template>

<script>
import { getGroup } from "@/functions/get-group";
import { getClassLevel } from "@/functions/class-level";
import { modifyGroup } from "@/functions/modify-group";
import { router } from "@/router";

import SuccessSnackbar from "@/components/AditionalComponents/SuccessSnackbar.vue";

const availableLevels = getClassLevel();

export default {
    components: {
        SuccessSnackbar,
    },
    data: () => ({
        dialog: false,
        group: {
            name: "",
            advancementLevel: null,
            maximumMembersCount: 0,
            description: "",
        },
        availableLevels: availableLevels,
        successNotification: false,
    }),
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    mounted() {
        this.fetchGroup();
    },
    methods: {
        async fetchGroup() {
            try {
                const returnedGroup = await getGroup(this.$store, this.id);
                this.group = returnedGroup;
            } catch (error) {
                console.log(error);
            }
        },
        modifyGroup(
            store,
            id,
            groupName,
            groupDescription,
            maxMembers,
            classLevel
        ) {
            modifyGroup(
                store,
                id,
                groupName,
                groupDescription,
                maxMembers,
                classLevel
            )
                .then((message) => {
                    this.successNotification = true;
                    this.notification = message;
                    setTimeout(() => {
                        this.goToPage("/groups");
                    }, 1500);
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        goToPage(route) {
            router.push(route);
        },
    },
};
</script>

<style scoped>
.button {
    font-weight: 400 !important;
    background: #0396a6 !important;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1) !important;
    border-radius: 100px !important;
    color: #fcfcfc !important;
    text-transform: unset !important;
}
</style>
