<template>
    <div>
        <AdminBar />
        <div class="main-card">
            <v-row>
                <v-col cols="2">
                    <SideToolbar />
                </v-col>
                <v-col>
                    <LoadingDialog />
                    <v-row>
                        <LocationInfo />
                        <div class="spacer"></div>
                        <div class="button-div">
                            <ModifyMember :id="id" />
                        </div>
                        <div class="button-div">
                            <AssignMember :id="id" />
                        </div>
                        <div class="button-div">
                            <DeleteMember :id="id" />
                        </div>
                    </v-row>
                    <v-row>
                        <v-col><MemberDetails :id="id" /></v-col>
                        <v-col><ContactInfo :id="id" /></v-col>
                    </v-row>
                </v-col>
            </v-row>
        </div>
        <MainFooter />
    </div>
</template>

<script>
import AdminBar from "@/components/AdminPanel/AdminBar.vue";
import SideToolbar from "@/components/AdminPanel/SideToolbar.vue";
import LocationInfo from "@/components/AdminPanel/LocationInfo.vue";
import AssignMember from "./MemberActions/AssignMember.vue";
import ModifyMember from "./MemberActions/ModifyMember.vue";
import MemberDetails from "./MemberDetails.vue";
import DeleteMember from "../../GroupsOverview/ManageGroup/MemberActions/DeleteMember.vue";
import ContactInfo from "./ContactInfo.vue";
import MainFooter from "@/components/MainFooter.vue";
import LoadingDialog from "@/components/AditionalComponents/LoadingDialog.vue";

export default {
    name: "AdminPanel",

    components: {
        AdminBar,
        LocationInfo,
        SideToolbar,
        AssignMember,
        ModifyMember,
        DeleteMember,
        MemberDetails,
        ContactInfo,
        MainFooter,
        LoadingDialog,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
    },
};
</script>

<style scoped>
.main-card {
    margin: 25px 75px 224px 75px;
}

.spacer {
    flex-grow: 1;
}

.button-div {
    margin: 20px;
}
</style>
