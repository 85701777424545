<template>
    <div>
        <AdminBar />
        <div class="main-card">
            <v-row>
                <v-col cols="2">
                    <SideToolbar />
                </v-col>
                <v-col>
                    <LoadingDialog />
                    <v-row>
                        <LocationInfo />
                        <v-row class="button-row">
                            <div class="spacer"></div>
                            <div class="button-div">
                                <a
                                    class="button"
                                    @click="goToPage('/createGroup')"
                                    >Nowa grupa</a
                                >
                            </div>
                        </v-row>
                    </v-row>
                    <v-row>
                        <DetailedDataBlock />
                    </v-row>
                </v-col>
            </v-row>
        </div>
        <MainFooter />
    </div>
</template>

<script>
import { router } from "@/router";
import AdminBar from "@/components/AdminPanel/AdminBar.vue";
import SideToolbar from "@/components/AdminPanel/SideToolbar.vue";
import LocationInfo from "@/components/AdminPanel/LocationInfo.vue";
import DetailedDataBlock from "@/components/AdminPanel/GroupsOverview/DetailedDataBlock.vue";
import LoadingDialog from "@/components/AditionalComponents/LoadingDialog.vue";
import MainFooter from "@/components/MainFooter.vue";

export default {
    name: "AdminPanel",

    components: {
        AdminBar,
        LocationInfo,
        SideToolbar,
        DetailedDataBlock,
        LoadingDialog,
        MainFooter,
    },
    methods: {
        goToPage(route) {
            router.push(route);
        },
    },
};
</script>

<style scoped>
.main-card {
    margin: 25px 75px 224px 75px;
}

.button-row {
    justify-content: flex-end;
}

.spacer {
    flex-grow: 1;
}

.button-div {
    margin: 40px 125px 0px 27px;
}

.button {
    font-weight: 400;
    margin-right: 2px;
    background: #0396a6;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
    border-radius: 100px;
    color: #fcfcfc;
    text-transform: unset !important;
}
</style>
