<template>
    <v-container class="container">
        <v-row>
            <v-col
                v-for="group in groups"
                :key="group.id"
                cols="12"
                sm="6"
                md="4"
            >
                <v-card
                    class="mx-auto card"
                    max-width="600px"
                    variant="outlined"
                >
                    <v-card-item>
                        <div>
                            <div class="text-overline mb-1">
                                <v-row>
                                    <v-col>
                                        <h3>Dzień</h3>
                                    </v-col>
                                    <v-col>
                                        <h3>Godzina</h3>
                                    </v-col>
                                </v-row>
                            </div>
                            <div
                                v-for="event in group.events"
                                :key="event.id"
                                class="text-h6 mb-1"
                            >
                                <v-row>
                                    <v-col>
                                        {{
                                            getTranslatedDayOfWeek(
                                                event.dayOfWeek
                                            )
                                        }}
                                    </v-col>
                                    <v-col>
                                        {{ event.time }}
                                    </v-col>
                                </v-row>
                            </div>
                        </div>
                    </v-card-item>
                    <v-divider></v-divider>
                    <v-card-item>
                        <div>
                            <div>
                                <v-row>
                                    <v-col text-align="center">
                                        <p class="text">
                                            Liczba zajęć w tygodniu
                                            {{ group.events.length }}
                                        </p>
                                    </v-col>
                                </v-row>
                            </div>
                        </div>
                    </v-card-item>

                    <v-card-actions>
                        <v-btn
                            variant="outlined"
                            color="#0396a6"
                            @click="
                                selectGroup(group.id, group.advancementLevel)
                            "
                        >
                            Wybierz
                        </v-btn>
                        <v-spacer></v-spacer>

                        <v-btn
                            :icon="
                                group.id === expandedGroupId
                                    ? 'mdi-chevron-up'
                                    : 'mdi-chevron-down'
                            "
                            @click="toggleExpandedGroup(group.id)"
                        ></v-btn>
                    </v-card-actions>
                    <v-expand-transition>
                        <div v-show="group.id == expandedGroupId">
                            <v-divider></v-divider>

                            <v-card-text>
                                <p class="text-h6 text--primary">Opis</p>
                                <p class="text">{{ group.description }}</p>
                            </v-card-text>
                        </div>
                    </v-expand-transition>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    data: () => ({
        show: false,
        expandedGroupId: null,
    }),
    props: {
        groups: {
            type: Array,
            required: true,
        },
    },
    methods: {
        getTranslatedDayOfWeek(dayOfWeek) {
            const translations = {
                MONDAY: "Poniedziałek",
                TUESDAY: "Wtorek",
                WEDNESDAY: "Środa",
                THURSDAY: "Czwartek",
                FRIDAY: "Piątek",
                SATURDAY: "Sobota",
                SUNDAY: "Niedziela",
            };
            return translations[dayOfWeek] || dayOfWeek;
        },
        selectGroup(groupId, advancementLevel) {
            this.$emit("group-selected", groupId, advancementLevel);
        },
        toggleExpandedGroup(groupId) {
            this.expandedGroupId =
                this.expandedGroupId === groupId ? null : groupId;
        },
    },
};
</script>

<style scoped>
.container {
    color: black;
    width: 100%;
}

.card {
    width: 100%;
    background-color: white;
}
</style>
