import axios from "axios";

const apiURL = process.env.VUE_APP_API_URL;

export function getGroups(store) {
    const config = {
        headers: {
            Authorization: "Bearer " + store.state.token,
        },
    };

    return axios.get(apiURL + "group/list", config).then((response) => {
        const returnedGroups = [...response.data];

        const transformedGroups = returnedGroups.map(function (obj) {
            var result = {
                id: obj.id,
                name: obj.name,
                description: obj.description,
                advancementLevel: obj.advancementLevel,
                members: obj.members,
                events: obj.events,
            };

            for (let id in obj) {
                if (Object.prototype.hasOwnProperty.call(obj, id)) {
                    result[id] = obj[id];
                }
            }

            return result;
        });

        return [...transformedGroups];
    });
}
