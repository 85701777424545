<template>
    <v-row justify="center">
        <v-dialog
            v-model="dialog"
            persistent
            width="1024"
            transition="dialog-bottom-transition"
        >
            <template v-slot:activator="{ props }">
                <a class="button" v-bind="props"> Nowy uczestnik </a>
            </template>
            <v-form
                @submit.prevent="
                    createMember(
                        this.memberName,
                        this.memberSurname,
                        this.memberAge,
                        this.memberEmail,
                        this.memberPhoneNumber,
                        this.advancementLevel,
                        this.id
                    )
                "
            >
                <v-card>
                    <v-card-title>
                        <span class="text-h5">Stwórz nowego uczestnika</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12" sm="6" md="4">
                                    <v-text-field
                                        variant="outlined"
                                        label="Imie"
                                        required
                                        v-model="memberName"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                    <v-text-field
                                        variant="outlined"
                                        label="Nazwisko"
                                        required
                                        v-model="memberSurname"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                    <v-text-field
                                        variant="outlined"
                                        label="Wiek"
                                        required
                                        v-model="memberAge"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                    <v-text-field
                                        variant="outlined"
                                        label="E-mail"
                                        required
                                        v-model="memberEmail"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                    <v-text-field
                                        variant="outlined"
                                        label="Telefon"
                                        v-model="memberPhoneNumber"
                                        :prefix="'+' + 48"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                    <v-select
                                        required
                                        class="input"
                                        bg-color="white"
                                        variant="outlined"
                                        v-model="advancementLevel"
                                        item-text="title"
                                        item-value="value"
                                        label="Poziom"
                                        :items="availableLevels"
                                    ></v-select>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="blue"
                            variant="text"
                            @click="dialog = false"
                        >
                            Anuluj
                        </v-btn>
                        <v-btn color="success" variant="text" type="submit">
                            Dodaj
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
    </v-row>
    <SuccessSnackbar
        :ref="successDialog"
        :start="successNotification"
        :notificationText="notification"
    />
</template>

<script>
import { createMember } from "@/functions/create-member";
import { getClassLevel } from "@/functions/class-level";
import { router } from "@/router";

import SuccessSnackbar from "@/components/AditionalComponents/SuccessSnackbar.vue";

const availableLevels = getClassLevel();

export default {
    data: () => ({
        dialog: false,
        memberName: "",
        memberSurname: "",
        memberAge: "",
        memberEmail: "",
        memberPhoneNumber: "",
        advancementLevel: null,
        availableLevels: availableLevels,
        successNotification: false,
    }),
    components: {
        SuccessSnackbar,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    methods: {
        createMember(
            memberName,
            memberSurname,
            memberAge,
            memberEmail,
            memberPhoneNumber,
            advancementLevel,
            requestedGroupId
        ) {
            createMember(
                memberName,
                memberSurname,
                memberAge,
                memberEmail,
                memberPhoneNumber,
                advancementLevel,
                requestedGroupId
            )
                .then((message) => {
                    this.successNotification = true;
                    this.notification = message;
                    setTimeout(() => {
                        this.goToPage("/members");
                    }, 1500);
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        goToPage(route) {
            router.push(route);
        },
    },
};
</script>

<style scoped>
.button {
    font-weight: 400 !important;
    background: #0396a6 !important;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1) !important;
    border-radius: 100px !important;
    color: #fcfcfc !important;
    text-transform: unset !important;
}
</style>
