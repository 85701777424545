<template>
    <div class="text-center">
        <v-dialog v-model="dialog" :scrim="true" persistent width="auto">
            <v-card class="card">
                <v-card-text>
                    Ładowanie danych
                    <v-progress-linear
                        indeterminate
                        color="white"
                        class="mb-0"
                    ></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            dialog: true,
        };
    },
    mounted() {
        this.timeOut();
    },
    methods: {
        timeOut() {
            setTimeout(() => (this.dialog = false), 1250);
        },
    },
};
</script>

<style scoped>
.card {
    color: #0396a6;
    background-color: #e7f2f3;
}
</style>
