<template>
    <v-container class="block">
        <v-table fixed-header height="155px">
            <thead>
                <tr>
                    <th class="text-left"><h4>Adres e-mail</h4></th>
                    <th class="text-left"><h4>Numer telefonu</h4></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="text-left">{{ member.email }}</td>
                    <td class="text-left">{{ member.phoneNumber }}</td>
                </tr>
            </tbody>
        </v-table>
    </v-container>
</template>

<script>
import { getMember } from "@/functions/get-member";

export default {
    data() {
        return {
            member: {},
        };
    },
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    mounted() {
        this.fetchMember();
    },
    methods: {
        async fetchMember() {
            try {
                const returnedMember = await getMember(this.$store, this.id);
                this.member = returnedMember;
            } catch (error) {
                console.log(error);
            }
        },
    },
};
</script>

<style scoped>
.block {
    max-height: 100%;
    text-align: center;
    border: 1px solid #0396a6;
    border-radius: 25px;
}
</style>
