<template>
    <v-container class="container">
        <h2>Danie uczestnika</h2>
        <v-form
            @submit="
                createMember(
                    this.member.name,
                    this.member.surname,
                    this.member.age,
                    this.member.email,
                    this.member.phoneNumber,
                    advancementLevel,
                    groupId
                )
            "
        >
            <div class="form-data">
                <v-row>
                    <v-col>
                        <v-text-field
                            required
                            class="input"
                            bg-color="white"
                            variant="outlined"
                            v-model="member.name"
                            label="Imię (wymagane)"
                            :rules="nameRules"
                            type="string"
                        ></v-text-field>
                        <v-text-field
                            required
                            class="input"
                            bg-color="white"
                            variant="outlined"
                            v-model="member.surname"
                            label="Nazwisko (wymagane)"
                            :rules="nameRules"
                            type="string"
                        ></v-text-field>
                        <v-text-field
                            required
                            class="input"
                            bg-color="white"
                            variant="outlined"
                            v-model="member.age"
                            label="Wiek (wymagane)"
                            type="number"
                            hint="Można zapisać dziecko w przedziale wiekowym od 4 do 18 lat"
                            persistent-hint=""
                            :rules="ageRules"
                        ></v-text-field> </v-col
                    ><v-col>
                        <v-text-field
                            required
                            class="input"
                            bg-color="white"
                            variant="outlined"
                            v-model="member.email"
                            label="Adres e-mail (wymagane)"
                            :rules="emailRules"
                            type="string"
                        ></v-text-field>
                        <v-text-field
                            required
                            class="input"
                            bg-color="white"
                            variant="outlined"
                            v-model="member.phoneNumber"
                            label="Numer telefonu (wymagane)"
                            hint="Numet telefonu powinien być w formacie +48 XXXXXXXXX"
                            :rules="phoneNumberRules"
                            :prefix="'+' + 48"
                            type="string"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <p>
                            * Wyrażam zgodę na przetwarzanie moich danych
                            osobowych zgodnie z ustawą o ochronie danych
                            osobowych w związku z realizacją zgłoszenia. Podanie
                            danych jest dobrowolne, ale niezbędne do
                            przetworzenia zapytania. Zostałem /am poinformowany
                            /a, że przysługuje mi prawo dostępu do swoich
                            danych, możliwości ich poprawiania, żądania
                            zaprzestania ich przetwarzania. Administratorem
                            danych osobowych jest Myślenickie Stowarzyszenie
                            Pływackie Aquarius z siedzibą ul. Ogrodowa 19,
                            32-400 Myślenice. *
                        </p>
                        <v-checkbox
                            label="Zgadzam się"
                            color="black"
                            required
                        ></v-checkbox>
                    </v-col>
                </v-row>

                <v-row class="center-row">
                    <v-btn class="button" type="submit"
                        >Wyślij zapis</v-btn
                    ></v-row
                >
            </div>
        </v-form>
    </v-container>
</template>

<script>
import { createMember } from "@/functions/create-member";
import {
    formRules,
    emailRules,
    nameRules,
    phoneNumberRules,
    ageRules,
} from "@/functions/member-form-rules";

export default {
    props: {
        groupId: {
            type: Number,
            required: true,
        },
        advancementLevel: {
            type: String,
            required: true,
        },
    },
    data: () => ({
        member: {
            name: "",
            surname: "",
            age: null,
            email: "",
            phoneNumber: "",
        },
        basicRule: [formRules],
        emailRules: [formRules, emailRules],
        nameRules: [formRules, nameRules],
        phoneNumberRules: [formRules, phoneNumberRules],
        ageRules: [formRules, ageRules],
    }),
    methods: {
        createMember,
    },
};
</script>

<style scoped>
.form-data {
    color: black;
}
.center-row {
    justify-content: center;
    color: black;
}
.button {
    font-weight: 400;
    margin-right: 2px;
    background: #0396a6;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
    border-radius: 100px;
    color: #fcfcfc;
    text-transform: unset !important;
}
</style>
