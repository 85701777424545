import axios from "axios";

const apiURL = process.env.VUE_APP_API_URL;

export function getGroup(store, id) {
    const config = {
        headers: {
            Authorization: "Bearer " + store.state.token,
        },
    };

    return axios.get(apiURL + "group/" + id, config).then((response) => {
        let returnedGroup = {
            id: response.data.id,
            name: response.data.name,
            description: response.data.description,
            maximumMembersCount: response.data.maximumMembersCount,
            advancementLevel: response.data.advancementLevel,
            members: [...response.data.members],
            events: [...response.data.events],
        };

        const levelMapping = {
            ADVANCED: "Doskonalenie pływania",
            BASIC: "Nauka pływania",
            EXPERT: "Poziom wysokozaawansowany",
        };

        const eventDayMapping = {
            MONDAY: "Poniedziałek",
            TUESDAY: "Wtorek",
            WEDNESDAY: "Środa",
            THURSDAY: "Czwartek",
            FRIDAY: "Piątek",
            SUNDAY: "Sobota",
            SATURDAY: "Niedziela",
        };

        returnedGroup.advancementLevel =
            levelMapping[returnedGroup.advancementLevel] ||
            returnedGroup.advancementLevel;

        returnedGroup.events.forEach((event) => {
            event.dayOfWeek =
                eventDayMapping[event.dayOfWeek] || event.dayOfWeek;
        });
        return returnedGroup;
    });
}

export function getGroupMembers(store, id) {
    const config = {
        headers: {
            Authorization: "Bearer " + store.state.token,
        },
    };

    return axios.get(apiURL + "group/" + id, config).then((response) => {
        let returnedGroupMembers = {
            members: [...response.data.members],
        };
        return returnedGroupMembers;
    });
}
