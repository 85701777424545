<template>
    <v-container class="block">
        <v-table fixed-header height="155px">
            <thead>
                <tr>
                    <th class="text-left"><h4>Imie</h4></th>
                    <th class="text-left"><h4>Nazwisko</h4></th>
                    <th class="text-left"><h4>Wiek</h4></th>
                    <th class="text-left"><h4>Poziom</h4></th>

                    <th class="text-left"><h4>Sugerowana grupa</h4></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="text-left">{{ member.name }}</td>
                    <td class="text-left">{{ member.surname }}</td>
                    <td class="text-left">{{ member.age }}</td>
                    <td class="text-left">{{ member.advancementLevel }}</td>
                    <td class="text-left">{{ group.name }}</td>
                </tr>
            </tbody>
        </v-table>
    </v-container>
</template>

<script>
import { getMember } from "@/functions/get-member";
import { getGroup } from "@/functions/get-group";

export default {
    data() {
        return {
            member: {},
            group: {},
        };
    },
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    mounted() {
        this.fetchMember();
    },
    methods: {
        async fetchMember() {
            try {
                const returnedMember = await getMember(this.$store, this.id);
                this.member = returnedMember;
                this.fetchGroup();
            } catch (error) {
                console.log(error);
            }
        },
        async fetchGroup() {
            try {
                const returnedGroup = await getGroup(
                    this.$store,
                    this.member.requestedGroupId
                );
                this.group = returnedGroup;
            } catch (error) {
                console.log(error);
            }
        },
    },
};
</script>

<style scoped>
.block {
    max-height: 100%;
    text-align: center;
    border: 1px solid #0396a6;
    border-radius: 25px;
}
</style>
