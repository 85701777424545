import axios from "axios";
import { router } from "../router";

const apiURL = process.env.VUE_APP_API_URL;

export const modifyMember = (
    store,
    id,
    memberName,
    memberSurname,
    memberAge,
    memberEmail,
    memberPhoneNumber,
    advancementLevel,
    requestedGroupId
) => {
    const config = {
        headers: {
            Authorization: "Bearer " + store.state.token,
        },
    };

    const memberData = {
        name: memberName,
        surname: memberSurname,
        age: memberAge,
        email: memberEmail,
        phoneNumber: memberPhoneNumber,
        advancementLevel: advancementLevel,
        requestedGroupId: requestedGroupId,
    };

    axios
        .put(apiURL + "member/" + id, memberData, config)
        .then(() => {
            router.push("/members");
        })
        .catch((error) => {
            console.log(error.response.data);
        });
};
