<template>
    <v-sheet class="login-form mx-auto rounded-lg">
        <v-row>
            <h1 class="text-center">Panel Logowania</h1>
        </v-row>
        <v-form @submit.prevent="loginAction(this.username, this.password)">
            <v-row class="user-input">
                <v-text-field
                    variant="outlined"
                    v-model="username"
                    :rules="rule1"
                    label="E-mail"
                ></v-text-field>
            </v-row>
            <v-row class="user-input">
                <v-text-field
                    type="password"
                    variant="outlined"
                    v-model="password"
                    :rules="rule2"
                    label="Hasło"
                    required
                ></v-text-field>
            </v-row>
            <v-row class="user-input">
                <v-btn
                    class="button"
                    size="large"
                    variant="elevated"
                    type="submit"
                >
                    Zaloguj
                </v-btn>
                <a href="/">Zapomniałeś hasła?</a>
            </v-row>
        </v-form>
    </v-sheet>
</template>

<script>
import loginAction from "@/functions/user-login-java";

export default {
    name: "LoginPage",
    data: () => ({
        username: "",
        rule1: [
            (value) => {
                if (value) return true;
                return "You must enter a username.";
            },
        ],
        password: "",
        rule2: [
            (value) => {
                if (value) return true;
                return "You must enter a password.";
            },
        ],
    }),
    methods: {
        loginAction,
    },
};
</script>

<style scoped>
h1 {
    margin-bottom: 25px;
    font-size: 3rem;
}

.login-form {
    margin-top: 75px;
    padding: 25px 0px;
    color: #0396a6;
    width: 85%;
}

.user-input {
    margin: 10px 50px 0 0;
}

.button {
    font-weight: 400;
    margin-right: 2px;
    background: #0396a6;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
    border-radius: 100px;
    color: #fcfcfc;
    text-transform: unset !important;
}

a {
    font-style: italic;
    margin: 3px 5px;
    display: inline-block;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    color: #a0d3d9;
    border-radius: 0.25rem;
    border: none;
    cursor: pointer;
}

a:hover {
    color: #0396a6;
}
</style>
