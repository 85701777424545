<template>
    <v-container class="block">
        <v-table fixed-header height="500px">
            <thead>
                <tr>
                    <th class="text-left"><h4>Imie</h4></th>
                    <th class="text-left"><h4>Nazwisko</h4></th>
                    <th class="text-left"><h4>E-mail</h4></th>
                    <th class="text-left"><h4>Poziom</h4></th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="member in members" :key="member.id">
                    <td class="text-left">{{ member.name }}</td>
                    <td class="text-left">{{ member.surname }}</td>
                    <td class="text-left">{{ member.email }}</td>
                    <td class="text-left">
                        {{
                            getTranslatedAdvancementLevel(
                                member.advancementLevel
                            )
                        }}
                    </td>
                    <td class="text-left">
                        <a class="button" @click="goToMember(member.id)"
                            >Więcej</a
                        >
                    </td>
                </tr>
            </tbody>
        </v-table>
    </v-container>
</template>

<script>
import { getMembers } from "@/functions/get-members";
import { getTranslatedAdvancementLevel } from "@/functions/class-level";
import { router } from "@/router";

export default {
    data: () => {
        return {
            members: [],
        };
    },
    mounted() {
        this.fetchMembers();
    },
    methods: {
        async fetchMembers() {
            try {
                const returnedMembers = await getMembers(this.$store);
                this.members = returnedMembers;
            } catch (error) {
                console.log(error);
            }
        },
        goToMember(id) {
            router.push({ name: "Uczestnik", params: { id } });
        },
        getTranslatedAdvancementLevel,
    },
};
</script>

<style scoped>
.block {
    max-height: 100%;
    text-align: center;
    border: 1px solid #0396a6;
    border-radius: 25px;
}

.button {
    font-weight: 400;
    margin-right: 2px;
    background: #0396a6;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
    border-radius: 100px;
    color: #fcfcfc;
    text-transform: unset !important;
}
</style>
