<template>
    <p>Nowe osoby oczekujące: {{ this.membersCount }}</p>
</template>

<script>
import { getUnassignedMembers } from "@/functions/get-unassigned-members";

export default {
    data: () => ({
        membersCount: null,
    }),
    mounted() {
        this.fetchUnassignedMembers();
    },
    methods: {
        async fetchUnassignedMembers() {
            try {
                const returnedMembers = await getUnassignedMembers(this.$store);
                this.membersCount = returnedMembers.length;
            } catch (error) {
                console.log(error);
            }
        },
    },
};
</script>

<style scoped>
p {
    color: #ea0707;
    margin: 0px 20px 20px 20px;
}
</style>
