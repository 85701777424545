<template>
    <v-footer class="footer">Copyright © 2023</v-footer>
</template>

<style scoped>
.footer {
    justify-content: center;
    border-top: 1px solid #a0d3d9;
    background-color: white;
    padding: 40px 15px;
    margin: 0px 125px;
    width: auto;
}
</style>
