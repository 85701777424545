import axios from "axios";
const apiURL = process.env.VUE_APP_API_URL;

const messages = {
    positive: "Uczestnik został dodany do listy oczekujących",
    error: "Pojawił się bład: ",
};

export const createMember = (
    memberName,
    memberSurname,
    memberAge,
    memberEmail,
    memberPhoneNumber,
    advancementLevel,
    requestedGroupId
) => {
    return new Promise((resolve, reject) => {
        const memberData = {
            name: memberName,
            surname: memberSurname,
            age: memberAge,
            email: memberEmail,
            phoneNumber: memberPhoneNumber,
            advancementLevel: advancementLevel,
            requestedGroupId: requestedGroupId,
        };

        axios
            .post(apiURL + "public/member", memberData)
            .then(() => {
                resolve(messages.positive);
            })
            .catch((error) => {
                reject(messages.error + error.response.data);
            });
    });
};
