<template>
    <div>
        <v-snackbar color="success" v-model="snackbar" :timeout="timeout">
            <div class="text-subtitle-1 pb-2">{{ notificationText }}</div>
            <template v-slot:actions>
                <v-btn color="white" variant="text" @click="snackbar = false">
                    Zamknij
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
export default {
    data() {
        return {
            snackbar: false,
            text: "My timeout is set to 2000.",
            timeout: 1500,
        };
    },

    props: {
        start: {
            type: Boolean,
            required: true,
        },
        notificationText: {
            type: String,
            required: true,
        },
    },

    watch: {
        start(newVal) {
            if (newVal !== undefined) {
                this.snackbar = newVal;
            }
        },
    },
};
</script>

<style scoped>
.block {
    background-color: white;
}
</style>
