<template>
    <v-row justify="center">
        <v-dialog
            v-model="dialog"
            persistent
            width="1024"
            transition="dialog-bottom-transition"
        >
            <template v-slot:activator="{ props }">
                <a class="button" v-bind="props"> Nowe wydarzenie </a>
            </template>
            <v-form
                @submit.prevent="
                    createEvent($store, this.id, this.dayOfWeek, this.time)
                "
            >
                <v-card>
                    <v-card-title>
                        <span class="text-h5">Stwórz nowe wydarzenie</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <v-select
                                        variant="outlined"
                                        label="Dzień"
                                        :items="availableDays"
                                        item-text="title"
                                        item-value="value"
                                        v-model="dayOfWeek"
                                        required
                                    ></v-select>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-text-field
                                        variant="outlined"
                                        label="Godzina"
                                        hint="*Format godziny musi być następujacy HH:MM:SS, przykład: 17:30:00"
                                        required
                                        v-model="time"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="blue"
                            variant="text"
                            @click="dialog = false"
                        >
                            Anuluj
                        </v-btn>
                        <v-btn color="success" variant="text" type="submit">
                            Dodaj
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
    </v-row>
</template>

<script>
import { createEvent } from "@/functions/create-event";
import { getDays } from "@/functions/event-data";

const availableDays = getDays();

export default {
    data: () => ({
        dialog: false,
        dayOfWeek: null,
        time: "",
        availableDays: availableDays,
    }),
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    methods: {
        createEvent,
    },
};
</script>

<style scoped>
.button {
    font-weight: 400 !important;
    background: #0396a6 !important;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1) !important;
    border-radius: 100px !important;
    color: #fcfcfc !important;
    text-transform: unset !important;
}
</style>
