<template>
    <v-row justify="center">
        <v-dialog
            v-model="dialog"
            persistent
            width="1024"
            transition="dialog-bottom-transition"
        >
            <template v-slot:activator="{ props }">
                <a class="button" v-bind="props"> Modyfikuj </a>
            </template>
            <v-form
                @submit.prevent="
                    modifyMember(
                        $store,
                        id,
                        this.member.name,
                        this.member.surname,
                        this.member.age,
                        this.member.email,
                        this.member.phoneNumber,
                        this.member.advancementLevel,
                        this.member.requestedGoupId
                    )
                "
            >
                <v-card loading>
                    <v-card-title>
                        <span class="text-h5">Modyfikowanie uczestnika</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12" sm="6" md="4">
                                    <v-text-field
                                        variant="outlined"
                                        label="Imie"
                                        v-model="member.name"
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                    <v-text-field
                                        variant="outlined"
                                        label="Nazwisko"
                                        v-model="member.surname"
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                    <v-text-field
                                        variant="outlined"
                                        label="Age"
                                        type="number"
                                        v-model="member.age"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                    <v-select
                                        variant="outlined"
                                        label="Poziom"
                                        v-model="member.advancementLevel"
                                        item-text="title"
                                        item-value="value"
                                        :items="availableLevels"
                                        hint="To pole zawsze musi zostać kliknięte i wybrane"
                                        persistent-hint
                                    ></v-select>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                    <v-text-field
                                        variant="outlined"
                                        label="Adres mailowy"
                                        v-model="member.email"
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                    <v-text-field
                                        variant="outlined"
                                        label="Numer telefonu"
                                        v-model="member.phoneNumber"
                                        required
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="blue"
                            variant="text"
                            @click="dialog = false"
                        >
                            Zamknij
                        </v-btn>
                        <v-btn color="success" variant="text" type="submit">
                            Zapisz
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
    </v-row>
</template>

<script>
import { getMember } from "@/functions/get-member";
import { getClassLevel } from "@/functions/class-level";
import { modifyMember } from "@/functions/modify-member";

const availableLevels = getClassLevel();

export default {
    data: () => ({
        dialog: false,
        member: {
            name: "",
            surname: "",
            age: "",
            email: "",
            phoneNumber: "",
            advancementLevel: "",
            requestedGroupId: Number,
        },
        availableLevels: availableLevels,
    }),
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    mounted() {
        this.fetchMember();
    },
    methods: {
        async fetchMember() {
            try {
                const returnedMember = await getMember(this.$store, this.id);
                this.member = returnedMember;
            } catch (error) {
                console.log(error);
            }
        },
        modifyMember,
    },
};
</script>

<style scoped>
.button {
    font-weight: 400 !important;
    background: #0396a6 !important;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1) !important;
    border-radius: 100px !important;
    color: #fcfcfc !important;
    text-transform: unset !important;
}
</style>
