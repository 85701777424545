<template>
    <div>
        <AdminBar />
        <div class="main-card">
            <v-row>
                <v-col cols="2">
                    <SideToolbar />
                </v-col>
                <v-col>
                    <LoadingDialog />
                    <v-row>
                        <LocationInfo />
                    </v-row>
                    <v-row>
                        <DetailedAllMembersBlock />
                    </v-row>
                </v-col>
            </v-row>
        </div>
        <MainFooter />
    </div>
</template>

<script>
import AdminBar from "@/components/AdminPanel/AdminBar.vue";
import SideToolbar from "@/components/AdminPanel/SideToolbar.vue";
import LocationInfo from "@/components/AdminPanel/LocationInfo.vue";
import DetailedAllMembersBlock from "@/components/AdminPanel/MembersOverview/DetailedAllMembersBlock.vue";
import LoadingDialog from "@/components/AditionalComponents/LoadingDialog.vue";
import MainFooter from "@/components/MainFooter.vue";

export default {
    name: "AdminPanel",

    components: {
        AdminBar,
        LocationInfo,
        SideToolbar,
        DetailedAllMembersBlock,
        LoadingDialog,
        MainFooter,
    },
};
</script>

<style scoped>
.main-card {
    margin: 25px 75px 224px 75px;
}
</style>
