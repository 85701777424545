import axios from "axios";
import { router } from "@/router";

const apiURL = process.env.VUE_APP_API_URL;

export default function deleteGroup(store, id) {
    const config = {
        headers: {
            Authorization: "Bearer " + store.state.token,
        },
    };

    return axios.delete(apiURL + "group/" + id, config).then((response) => {
        console.log(response);
        router.push("/Groups");
    });
}
