export const formRules = (value) => {
    if (value === "" || value == null) {
        return "Uzupełnij pole.";
    } else {
        return true;
    }
};

export const emailRules = (value) => {
    if (/.+@.+\..+/.test(value)) return true;
    return "Adres mailowy musi być właściwy.";
};

export const nameRules = (value) => {
    if (!/[^0-9]$/.test(value)) {
        return "To pole nie może zawierać cyfr.";
    } else if (value?.length > 20)
        return "Pole nie może być dłuższe niż 20 znaków.";
    else {
        return true;
    }
};

export const phoneNumberRules = (value) => {
    if (!/^\d*$/.test(value)) {
        return "Pole nie może zawierać liter.";
    } else if (value.length > 12) {
        return "Numer telefonu jest zbyt długi.";
    } else {
        return true;
    }
};

export const ageRules = (value) => {
    if (value > 3 && value < 19) {
        return true;
    } else {
        return "Przedział wiekowy musi się znajdować między 4 a 19.";
    }
};
