import axios from "axios";
import { router } from "../router";

const apiURL = process.env.VUE_APP_API_URL;

export const removeMember = (store, groupId, memberId) => {
    const config = {
        headers: {
            Authorization: "Bearer " + store.state.token,
        },
    };

    axios
        .post(
            apiURL + "group/" + groupId + "/member/" + memberId + "/remove",
            {},
            config
        )
        .then(() => {
            router.push("/groups");
        })
        .catch((error) => {
            console.log(error.response.data);
        });
};
