<template>
    <v-toolbar class="basic-toolbar" density="default">
        <v-toolbar-title class="toolbar-title">CMA</v-toolbar-title>
        <v-spacer></v-spacer>
        <a class="link-button" href="/studentForm">Zapisy na zajęcia</a>
        <a class="link-button" href="/">Zaloguj się</a>
    </v-toolbar>
</template>

<script></script>

<style>
.basic-toolbar {
    border-bottom: 1px solid #a0d3d9;
    background-color: white;
    margin: 0px 125px;

    width: auto;
    padding: 15px 15px;
    color: #0396a6;
}

a {
    margin: 0px 5px;
    display: inline-block;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    color: #000000;
    border-radius: 0.25rem;
    border: none;
    cursor: pointer;
}

a:hover {
    color: #0396a6;
}
</style>
