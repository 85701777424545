export const getClassLevel = () => {
    return [
        { title: "Nauka pływania", value: "BASIC" },
        { title: "Doskonalenie pływania", value: "ADVANCED" },
        { title: "Poziom wysokozaawansowany", value: "EXPERT" },
    ];
};

export const getTranslatedAdvancementLevel = (advancementLevel) => {
    const translations = {
        BASIC: "Nauka pływania",
        ADVANCED: "Doskonalenie pływania",
        EXPERT: "Poziom wysokozaawansowany",
    };
    return translations[advancementLevel] || advancementLevel;
};
