<template>
    <NavBar />
    <v-container class="content">
        <h1>Zapisz dziecko na zajęcia</h1>
        <v-container class="form">
            <v-form @submit.prevent="fetchGroups(member.advancementLevel)">
                <v-container class="data-section">
                    <v-row>
                        <v-col>
                            <h2>Wybierz poziom zaawansowania</h2>
                        </v-col>
                        <v-col>
                            <v-select
                                required
                                class="input"
                                bg-color="white"
                                variant="outlined"
                                v-model="member.advancementLevel"
                                item-text="title"
                                item-value="value"
                                label="Poziom"
                                :rules="rule1"
                                :items="availableLevels"
                            ></v-select>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <p>
                                * Zajęcia grup nauki pływania odbywa się na
                                basenie rekreacyjnym (tzw. mały basen),
                                natomiast zajęcia grup doskonalenia pływania
                                odbywa się na basenie sportowym (tzw. duży
                                basen). Grupa początkująca – tor skrajny, grupa
                                zaawansowana – tor środkowy.
                            </p>
                        </v-col>
                    </v-row>
                </v-container>
                <v-row class="center-row">
                    <v-btn
                        class="button"
                        type="submit"
                        @click="showComponent = true"
                        >Sprawdź dostępne grupy</v-btn
                    ></v-row
                >
                <v-container class="data-section">
                    <v-row>
                        <v-col>
                            <p>
                                * Jeżeli nie pojawia się żadna grupa to oznacza,
                                że zapisy do grupy o wybranym poziomie nie są
                                obecnie możliwe.
                            </p>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
            <v-container class="data-section">
                <AvailableGroups
                    v-if="showComponent"
                    :groups="groups"
                    @group-selected="handleGroupSelected"
                />
            </v-container>
            <v-container class="data-section"
                ><FormData
                    :groupId="member.requestedGroupId"
                    :advancementLevel="member.advancementLevel"
                    v-if="showForm"
            /></v-container>
        </v-container>
    </v-container>
    <MainFooter />
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import MainFooter from "../MainFooter.vue";
import AvailableGroups from "./AvailableGroups.vue";
import FormData from "@/components/StudentForm/FormData.vue";

import { getClassLevel } from "@/functions/class-level";
import { selectGroupByLevel } from "@/functions/get-groups-public";

const availableLevels = getClassLevel();

export default {
    components: {
        NavBar,
        MainFooter,
        AvailableGroups,
        FormData,
    },
    data: () => ({
        member: {
            requestedGroupId: null,
            advancementLevel: null,
        },
        groups: [],
        relocationRoute: "/studentForm",
        availableLevels: availableLevels,
        showComponent: false,
        showForm: false,
    }),
    methods: {
        async fetchGroups(advancementLevel) {
            try {
                const returnedGroups = await selectGroupByLevel(
                    advancementLevel
                );
                this.groups = returnedGroups;
                this.showForm = false;
            } catch (error) {
                console.log(error);
            }
        },
        handleGroupSelected(groupId, level) {
            this.member = {
                requestedGroupId: groupId,
                advancementLevel: level,
            };
            this.showForm = true;
        },
    },
};
</script>

<style scoped>
h1 {
    color: #0396a6;
    text-align: center;
}

h2 {
    color: #0396a6;
    margin: 10px 10px;
}
.content {
    height: 90%;
}

p {
    color: black;
    margin: 0px 10px 15px 10px;
}

.form {
    height: 90%;
    width: 90%;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 20px;
    background-color: #e7f2f3;
}

.data-section {
    margin: 5px 80px;
    width: auto;
    color: #0396a6;
}

.input {
    margin: 5px 10px;
}

.button {
    font-weight: 400;
    margin-right: 2px;
    background: #0396a6;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
    border-radius: 100px;
    color: #fcfcfc;
    text-transform: unset !important;
}

.center-row {
    justify-content: center;
}
</style>
