import axios from "axios";
import { router } from "../router";

const apiURL = process.env.VUE_APP_API_URL;

export default function loginAction() {
    var token;
    let expiresAt;

    const username = this.username;
    const password = this.password;
    if (username && password) {
        axios
            .post(apiURL + "public/login", {
                username,
                password,
            })
            .then((response) => {
                token = response.data.token;
                expiresAt = response.data.expiresAt;
                this.$store.state.token = token;
                this.$store.state.username = username;
                this.$store.state.expiresAt = expiresAt;
                router.push("/adminpanel");
            });
    } else {
        console.error("Incorrect login credentials");
    }
    return token;
}
