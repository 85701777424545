import axios from "axios";

const apiURL = process.env.VUE_APP_API_URL;

export function getUnassignedMembers(store) {
    const config = {
        headers: {
            Authorization: "Bearer " + store.state.token,
        },
    };

    return axios
        .get(apiURL + "member/list/unassigned", config)
        .then((response) => {
            const returnedMembers = [...response.data];

            const transformedMembers = returnedMembers.map(function (obj) {
                var result = {
                    id: obj.id,
                    name: obj.name,
                    surname: obj.surname,
                    email: obj.email,
                    phoneNumber: obj.phoneNumber,
                    age: obj.age,
                    advancementLevel: obj.advancementLevel,
                    requestedGroupId: obj.requestedGroupId,
                };

                for (let id in obj) {
                    if (Object.prototype.hasOwnProperty.call(obj, id)) {
                        result[id] = obj[id];
                    }
                }

                return result;
            });

            return [...transformedMembers];
        });
}
