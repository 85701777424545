<template>
    <div>
        <AdminBar />
        <div class="main-card">
            <v-row>
                <v-col cols="2">
                    <v-row>
                        <SideToolbar />
                    </v-row>
                </v-col>
                <v-col>
                    <LoadingDialog />
                    <v-row>
                        <LocationInfo />
                        <v-row class="button-row">
                            <div class="spacer"></div>

                            <div class="button-div">
                                <ModifyGroup :id="id" />
                            </div>

                            <div class="button-div">
                                <DeleteGroup :id="id" />
                            </div>
                        </v-row>
                    </v-row>
                    <v-row class="details-row">
                        <v-col
                            ><h2 class="members-title">
                                Podstawowe informacje
                            </h2>
                            <DetailsBlock :id="id"
                        /></v-col>
                        <v-col
                            ><h2 class="members-title">Wydarzenia</h2>
                            <GroupEvents :id="id"
                        /></v-col>
                    </v-row>
                    <v-row class="details-row">
                        <v-col>
                            <h2 class="members-title">Lista uczestników</h2>
                            <GroupMembers :id="id"
                        /></v-col>
                        <v-col></v-col>
                    </v-row>
                </v-col>
            </v-row>
        </div>
        <MainFooter />
    </div>
</template>

<script>
import AdminBar from "../../../AdminBar.vue";
import SideToolbar from "../../../SideToolbar.vue";
import LocationInfo from "../../../LocationInfo.vue";
import DetailsBlock from "./DetailsBlock.vue";
import GroupMembers from "./GroupMembers.vue";
import ModifyGroup from "../ModifyGroup/ModifyGroup.vue";
import DeleteGroup from "../ModifyGroup/DeleteGroup.vue";
import MainFooter from "@/components/MainFooter.vue";
import LoadingDialog from "@/components/AditionalComponents/LoadingDialog.vue";

import GroupEvents from "./GroupEvents.vue";

export default {
    components: {
        AdminBar,
        SideToolbar,
        LocationInfo,
        DetailsBlock,
        GroupMembers,
        ModifyGroup,
        DeleteGroup,
        MainFooter,
        GroupEvents,
        LoadingDialog,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
    },
};
</script>

<style scoped>
.members-title {
    color: #0396a6;
    margin: 20px 10px;
}
.main-card {
    margin: 25px 75px 224px 75px;
}

.button-row {
    justify-content: flex-end;
    margin-right: 20px;
}

.spacer {
    flex-grow: 1;
}

.button-div {
    margin: 45px 0px 0px 10px;
}

.details-row {
    width: auto;
    margin-right: 90px;
}
</style>
