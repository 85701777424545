<template>
    <div>
        <NavBar />
        <div class="main-card">
            <v-row>
                <v-col>
                    <LoginForm />
                </v-col>
                <v-col>
                    <div>
                        <v-img
                            class="main-gif"
                            src="../assets/ilustration-login-screen.gif"
                            alt="GIF IMAGE"
                        />
                    </div>
                </v-col>
            </v-row>
        </div>
        <MainFooter />
    </div>
</template>

<script>
import LoginForm from "./LoginForm.vue";
import NavBar from "./NavBar.vue";
import MainFooter from "./MainFooter.vue";

export default {
    components: {
        LoginForm,
        NavBar,
        MainFooter,
    },
};
</script>

<style>
.main-card {
    margin: 124.5px 100px;
    padding: 0px 40px;
    height: 500px;
}

.main-gif {
    height: 550px;
}
</style>
