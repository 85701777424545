<template>
    <v-row justify="center">
        <v-dialog
            v-model="dialog"
            persistent
            width="1024px"
            transition="dialog-bottom-transition"
        >
            <template v-slot:activator="{ props }">
                <a class="button" v-bind="props"> Dodaj do grupy</a>
            </template>
            <v-card>
                <v-card-title class="text-h5">
                    Przypisz uczestnika
                </v-card-title>
                <v-card-text
                    ><v-table fixed-header>
                        <thead>
                            <tr>
                                <th class="text-center">
                                    <h4>Nazwa grupy</h4>
                                </th>
                                <th class="text-center">
                                    <h4>Poziom</h4>
                                </th>
                                <th class="text-center">
                                    <h4>Liczba uczestników</h4>
                                </th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="group in groups" :key="group.id">
                                <td class="text-center">{{ group.name }}</td>
                                <td class="text-center">
                                    {{ group.advancementLevel }}
                                </td>
                                <td class="text-center">
                                    {{ group.members.length }}/{{
                                        group.maximumMembersCount
                                    }}
                                </td>

                                <td>
                                    <v-btn
                                        color="success"
                                        variant="text"
                                        @click="
                                            addMember(this.$store, group.id, id)
                                        "
                                        >Przypisz</v-btn
                                    >
                                </td>
                            </tr>
                        </tbody>
                    </v-table></v-card-text
                >
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue" variant="text" @click="dialog = false">
                        Anuluj
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
    <SuccessSnackbar
        :ref="successDialog"
        :start="successNotification"
        :notificationText="notification"
    />
</template>

<script>
import { getGroups } from "@/functions/get-groups";
import { addMember } from "@/functions/assign-member";
import { router } from "@/router";

import SuccessSnackbar from "@/components/AditionalComponents/SuccessSnackbar.vue";

export default {
    components: {
        SuccessSnackbar,
    },
    data() {
        return {
            dialog: false,
            groups: [],
            successNotification: false,
        };
    },
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    mounted() {
        this.fetchGroups();
    },
    methods: {
        async fetchGroups() {
            try {
                const returnedGroups = await getGroups(this.$store);
                this.groups = returnedGroups;
            } catch (error) {
                console.log(error);
            }
        },
        addMember(store, groupId, memberId) {
            addMember(store, groupId, memberId)
                .then((message) => {
                    this.successNotification = true;
                    this.notification = message;
                    setTimeout(() => {
                        this.goToPage("/groups");
                    }, 1500);
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        goToPage(route) {
            router.push(route);
        },
    },
};
</script>

<style scoped>
.button {
    font-weight: 400 !important;
    background: #0396a6 !important;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1) !important;
    border-radius: 100px !important;
    color: #fcfcfc !important;
    text-transform: unset !important;
}
</style>
