import { createStore } from "vuex";

const store = createStore({
    state: {
        token: String,
        username: null,
        expiresAt: Date,
    },
    mutations: {
        setToken(state, token) {
            state.token = token;
        },
    },
});

export default store;
