export function checkLogin(store) {
    const token = store.state.token;
    const expiringTime = store.state.expiresAt;
    const userName = store.state.username;
    if (token && userName) {
        let currentTime = new Date();
        if (currentTime >= expiringTime) {
            return false;
        } else {
            return true;
        }
    } else {
        return false;
    }
}
