import { createRouter, createWebHistory } from "vue-router";
import LoginPage from "@/components/LoginPage.vue";
import AdminPanel from "@/components/AdminPanel/AdminPanel.vue";
import GroupsOverview from "@/components/AdminPanel/GroupsOverview/GroupsOverview.vue";
import CreateGroup from "@/components/AdminPanel/GroupsOverview/ManageGroup/CreateGroup/CreateGroup.vue";
import GroupDetails from "@/components/AdminPanel/GroupsOverview/ManageGroup/GroupDetailes/GroupDetails.vue";
import StudentForm from "@/components/StudentForm/StudentForm.vue";
import AllMembersOverview from "@/components/AdminPanel/MembersOverview/AllMembersOverview.vue";
import UnassignedMembersOverview from "@/components/AdminPanel/MembersOverview/UnassignedMembersOverview.vue";
import MemberOverview from "./components/AdminPanel/MembersOverview/ManageMember/MemberOverview.vue";
import { checkLogin } from "./functions/check-login";
import store from "./functions/token-store";

export const router = createRouter({
    history: createWebHistory(),
    routes: [
        { path: "/", name: "Strona główna", component: LoginPage },
        { path: "/adminpanel", name: "Panel główny", component: AdminPanel },
        { path: "/groups", name: "Twoje grupy", component: GroupsOverview },
        { path: "/createGroup", name: "Nowa grupa", component: CreateGroup },
        {
            path: "/group/:id",
            name: "Przegląd grupy",
            component: GroupDetails,
            props: true,
        },
        { path: "/studentForm", name: "Formularz", component: StudentForm },
        { path: "/members", name: "Uczestnicy", component: AllMembersOverview },
        {
            path: "/members/unassigned",
            name: "Oczekujący",
            component: UnassignedMembersOverview,
        },
        {
            path: "/member/:id",
            name: "Uczestnik",
            component: MemberOverview,
            props: true,
        },
    ],
});

router.beforeEach(async (to, from, next) => {
    if (to.name === "Strona główna" || to.name === "Formularz") {
        next();
    } else {
        const isAuthenticated = checkLogin(store);

        if (isAuthenticated) {
            next();
        } else {
            next({ name: "Strona główna" });
        }
    }
});
