import axios from "axios";
import { router } from "../router";

const apiURL = process.env.VUE_APP_API_URL;

export const deleteMember = (store, memberId) => {
    const config = {
        headers: {
            Authorization: "Bearer " + store.state.token,
        },
    };

    axios
        .delete(apiURL + "member/" + memberId, config)
        .then(() => {
            router.push("/members");
        })
        .catch((error) => {
            console.log(error.response.data);
        });
};
