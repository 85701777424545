<template>
    <div>
        <AdminBar />
        <div class="main-card">
            <v-row>
                <v-col cols="2">
                    <SideToolbar />
                </v-col>
                <v-col>
                    <v-row>
                        <LocationInfo />
                    </v-row>
                    <v-row class="form-row">
                        <CreateForm />
                    </v-row>
                </v-col>
            </v-row>
        </div>
        <MainFooter />
    </div>
</template>

<script>
import AdminBar from "../../../AdminBar.vue";
import SideToolbar from "../../../SideToolbar.vue";
import LocationInfo from "../../../LocationInfo.vue";
import CreateForm from "./CreateForm.vue";
import MainFooter from "@/components/MainFooter.vue";

export default {
    components: {
        AdminBar,
        SideToolbar,
        LocationInfo,
        CreateForm,
        MainFooter,
    },
};
</script>

<style scoped>
.main-card {
    margin: 25px 75px 224px 75px;
}

.form-row {
    width: 100%;
}
</style>
