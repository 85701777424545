import axios from "axios";

const apiURL = process.env.VUE_APP_API_URL;

export function getMember(store, id) {
    const config = {
        headers: {
            Authorization: "Bearer " + store.state.token,
        },
    };

    return axios.get(apiURL + "member/" + id, config).then((response) => {
        let returnedMember = {
            id: response.data.id,
            name: response.data.name,
            surname: response.data.surname,
            age: response.data.age,
            email: response.data.email,
            phoneNumber: response.data.phoneNumber,
            advancementLevel: response.data.advancementLevel,
            requestedGroupId: response.data.requestedGroupId,
        };

        const levelMapping = {
            ADVANCED: "Doskonalenie pływania",
            BASIC: "Nauka pływania",
            EXPERT: "Poziom wysokozaawansowany",
        };

        returnedMember.advancementLevel =
            levelMapping[returnedMember.advancementLevel] ||
            returnedMember.advancementLevel;

        return returnedMember;
    });
}
