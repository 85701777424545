import axios from "axios";
import { router } from "@/router";

const apiURL = process.env.VUE_APP_API_URL;

export const deleteEvent = (store, eventId) => {
    const config = {
        headers: {
            Authorization: "Bearer " + store.state.token,
        },
    };

    axios
        .delete(apiURL + "event/" + eventId, config)
        .then(() => {
            router.push("/groups");
        })
        .catch((error) => {
            console.log(error.response.data);
        });
};
