<template>
    <div class="form-div table-color">
        <form class="form" @submit.prevent="createGroup()">
            <v-row>
                <v-col>
                    <v-text-field
                        label="Nazwa grupy"
                        variant="outlined"
                        :counter="20"
                        maxlength="20"
                        v-model="groupName"
                        bg-color="white"
                    ></v-text-field>
                    <v-textarea
                        class="description"
                        label="Opis grupy"
                        variant="outlined"
                        :counter="120"
                        maxlength="120"
                        bg-color="white"
                        v-model="groupDescription"
                    ></v-textarea>
                </v-col>
                <v-col>
                    <v-row>
                        <v-col>
                            <v-text-field
                                label="Maksymalna liczba osób"
                                variant="outlined"
                                v-model="groupQuantity"
                                type="number"
                                bg-color="white"
                            ></v-text-field>
                            <v-select
                                transition="slide-x-transition"
                                label="Poziom"
                                variant="outlined"
                                :items="classLevel"
                                item-text="title"
                                item-value="value"
                                v-model="selctedLevel"
                                bg-color="white"
                            ></v-select>
                        </v-col>
                    </v-row>
                    <v-row class="button-row">
                        <div class="button-container">
                            <v-btn class="button" type="submit"
                                >Stwórz grupę</v-btn
                            >
                            <v-btn
                                class="red-button"
                                @click="goToPage('/groups')"
                                >Porzuć</v-btn
                            >
                        </div>
                    </v-row>
                </v-col>
            </v-row>
        </form>
    </div>
    <SuccessSnackbar
        :ref="successDialog"
        :start="successNotification"
        :notificationText="notification"
    />
</template>

<script>
import { createGroup } from "@/functions/create-group";
import { getClassLevel } from "@/functions/class-level";
import { router } from "@/router";

import SuccessSnackbar from "@/components/AditionalComponents/SuccessSnackbar.vue";

const availableLevels = getClassLevel();

export default {
    components: {
        SuccessSnackbar,
    },
    data: () => ({
        groupName: "",
        groupDescription: "",
        groupQuantity: "",
        classLevel: availableLevels,
        selctedLevel: null,
        successNotification: false,
    }),
    methods: {
        createGroup() {
            createGroup(
                this.$store,
                this.groupName,
                this.groupDescription,
                this.groupQuantity,
                this.selctedLevel
            )
                .then((message) => {
                    this.successNotification = true;
                    this.notification = message;
                    setTimeout(() => {
                        this.goToPage("/groups");
                    }, 1500);
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        goToPage(route) {
            router.push(route);
        },
    },
};
</script>

<style scoped>
.form-div {
    width: 80%;
    margin: 0px 115px 0px 20px;
    border-radius: 15px;
    width: 100%;
}

.form {
    padding: 20px;
    margin: 5px 20px;
    color: black;
    width: auto;
    color: #0396a6;
}

.button-row {
    display: flex;
}

.button-container {
    display: flex;
    margin: 0px 5px 5px 5px;
}

.button {
    font-weight: 400;
    margin: 0px 5px;
    background: #0396a6;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
    border-radius: 100px;
    color: #fcfcfc;
    text-transform: unset !important;
}

.clear-button {
    font-weight: 400;
    margin: 0px 5px;
    background: #7c96ab;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
    border-radius: 100px;
    color: #fcfcfc;
    text-transform: unset !important;
}

.red-button {
    font-weight: 400;
    margin: 0px 5px;
    background: #e76161;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
    border-radius: 100px;
    color: #fcfcfc;
    text-transform: unset !important;
}
</style>
