import axios from "axios";

const apiURL = process.env.VUE_APP_API_URL;

const messages = {
    positive: "Użytkownik został przypisany do grupy.",
    error: "Pojawił się bład: ",
};

export const addMember = (store, groupId, memberId) => {
    return new Promise((resolve, reject) => {
        const config = {
            headers: {
                Authorization: "Bearer " + store.state.token,
            },
        };

        axios
            .post(
                apiURL + "group/" + groupId + "/member/" + memberId + "/add",
                {},
                config
            )
            .then(() => {
                resolve(messages.positive);
            })
            .catch((error) => {
                reject(messages.error + error.response.data);
            });
    });
};
